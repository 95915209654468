export const reducer = (state, action) => {
  switch (action.type) {
    case "ACTION_TYPE":
      return { ...state, ...action.payload };
    case "CHANGE_ROUTE":
      return { ...state,  route: action.payload };
    case "CHANGE_COUNTRY":
      return { ...state,  country: action.payload };
    default:
      return state;
  }
};
