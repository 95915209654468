import React from "react";
import { useStore } from "Store/useStore";
import { Logo } from "Assets/logo_atw.js";
import qr_ita from "Assets/qr_ita.png";
import qr_pol from "Assets/qr_pol.png";

import "./Registration.scss";

const Registration = () => {
  const { state, dispatch } = useStore();

  const getHref =
    state.country === "ITA"
      ? "https://form.jotform.com/241203059798360"
      : "https://form.jotform.com/231702069790356";

  const onBack = () => {
    dispatch({ type: "CHANGE_ROUTE", payload: "/video" });
  };

  return (
    <div className="atw_registration_container">
      <Logo />
      <p>
        Scan the QR code or click <a href={getHref}>HERE</a><br/>to join the event.
      </p>
      {state.country === "ITA" && <img className="qrCode" alt="QR Code" href="" src={qr_ita} />}
      {state.country === "POL" && <img className="qrCode" alt="QR Code" href="" src={qr_pol} />}
      <a onClick={onBack}>Back to video</a>
    </div>
  );
};

export default Registration;
