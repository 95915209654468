import React from "react";

export const Logo = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 320.14 202.33">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M58.42,118.85c-4.67,9.34-7.75,12.89-14.35,13.47l-3.13,12.89l-5.61-3.16l2.58-10.19c-2.97-0.56-6.1-1.54-8.91-2.54
						c-3.57,6.41-8.25,11.59-14.63,12.91c-6.76,1.4-10.78-2.15-10.78-7.6c0-5.73,4.34-11.51,11.77-13.05
						c4.07-0.84,7.64-0.18,11.11,0.9L38.46,99.4c3.19-6.16,4.95-10.51,9.35-11.43c3.3-0.68,6.82,1.28,5.44,7.07l-8.03,32.49
						c3.79-0.56,6.32-1.93,10.61-10.06C56.77,117.34,58.09,118.02,58.42,118.85z M23.72,127.61c-2.69-0.73-5.44-1.23-8.25-0.65
						c-4.51,0.93-6.16,3.91-6.16,6.27c0,2.36,1.92,4.04,5.17,3.37C16.85,136.11,20.2,133.79,23.72,127.61z M39.17,126.83l7.86-30.88
						l-0.55-0.11l-14.79,28.45C34.5,125.32,37.03,126.2,39.17,126.83z"
              />
              <path
                d="M73.59,115.71c-4.34,8.65-9.4,16.04-15.18,17.24c-4.62,0.96-7.64-1.84-3.63-12.78l10.83-29.7l6.76-1.4l-11.66,31.9
						c-2.42,6.68-1.37,7.47-0.27,7.24c2.58-0.54,6.76-6.45,10.56-13.87C71.94,114.2,73.26,114.88,73.59,115.71z"
              />
              <path
                d="M88.77,112.57c-4.34,8.65-9.4,16.04-15.18,17.24c-4.62,0.96-7.64-1.84-3.63-12.78l10.83-29.7l6.76-1.4l-11.66,31.9
						c-2.42,6.68-1.37,7.47-0.28,7.24c2.58-0.54,6.76-6.45,10.56-13.87C87.12,111.06,88.44,111.74,88.77,112.57z"
              />
              <path
                d="M146.01,77.8c-2.14,0.44-5.5,0.8-8.19,0.97l-10.06,27.47c-4.45,12.55-11.6,16.27-16.44,17.28
						c-7.92,1.64-12.26-3.24-12.26-9.37c0-6.57,5.17-13.2,12.48-14.26v5.78c-4.95,0.63-6.82,4.67-6.82,7.48
						c0,3.09,2.36,5.52,6.38,4.69c5.39-1.12,8.8-6.65,11.6-14.76l8.85-24.24c-6.76-0.06-13.58-0.39-17.6,0.44
						c-4.95,1.02-6.49,3.87-6.49,6.17c0,2.64,1.98,3.69,4.12,3.25c2.36-0.49,4.12-2.65,4.12-2.65l2.75,3.7c0,0-2.53,3.39-6.98,4.31
						c-5.11,1.06-9.46-1.75-9.46-7.53c0-5.11,3.41-11.26,12.21-13.08c8.52-1.77,22.43,0.52,31.51-1.36c4.84-1,6.6-2.99,6.6-2.99
						l3.52,1.24C155.85,70.32,155.13,75.91,146.01,77.8z"
              />
              <path
                d="M165.8,96.62c-3.19,6.33-8.08,11-13.64,11.02c-2.64,3.97-6.16,7.17-10.5,8.07c-4.95,1.02-8.3-1.37-8.3-7.15
						c0-7.36,5.55-22.88,16.11-25.07c4.95-1.02,8.25,1.27,8.25,7.05c0,3.37-1.15,8.38-3.3,13.04c2.86-0.37,5.99-2.98,8.8-8.34
						C164.15,95.1,165.47,95.78,165.8,96.62z M146.61,106.26c-0.66-0.59-1.1-1.4-1.1-2.47c0-2.25,1.87-4.43,4.12-4.67
						c1.15-3.22,1.87-6.4,1.87-8.25c0-2.3-0.99-3-2.2-2.75c-4.67,0.97-9.73,15.27-9.73,20.15c0,2.02,0.88,3.07,2.31,2.78
						C143.48,110.73,145.13,108.81,146.61,106.26z"
              />
              <path
                d="M195.16,90.53c-5.66,11.28-12.21,16.01-17.76,19.85c0,0-0.77,2.13-1.48,4.01c-2.2,5.79-5.11,11.45-10.94,12.65
						c-4.01,0.83-6.16-1.2-6.05-4.42c0.17-3.57,3.19-6.56,6.16-8.58c1.92-1.35,5.11-3.31,6.32-4.29l1.59-4.43
						c0,0-2.42,4.38-6.27,5.17c-3.57,0.74-5.5-1.89-5.5-5.54c0-6.12,5.22-25,14.57-26.94c3.3-0.68,5.55,1.1,5.99,3.31l1.54-4.14
						l6.76-1.4l-10.89,29.6c5.17-3.77,9.46-8.47,13.36-16.24C193.51,89.02,194.83,89.7,195.16,90.53z M164.1,122.74
						c1.43-0.35,3.57-2.31,5.5-7.99c-1.59,1.12-3.02,1.92-4.51,3.18c-1.26,1.05-2.58,2.39-2.58,3.68
						C162.5,122.51,163.16,122.94,164.1,122.74z M175.65,98.23l4.23-11.55c0,0,0-3.71-2.64-3.16c-4.89,1.01-9.46,14.76-9.46,18.92
						c0,1.52,0.6,2.12,1.81,1.87C172.73,103.66,175.65,98.23,175.65,98.23z"
              />
              <path
                d="M220.24,85.34c-3.74,7.51-10.12,16.19-19.52,18.14c-6.27,1.3-9.51-1.23-9.51-7.01c0-7.86,6.21-22.91,16.28-24.99
						c5.66-1.17,9.24,2.69,5.94,10.56c-2.64,6.33-7.26,9.36-15.34,10.03c0,0-2.69,8.19,4.29,6.75c5.44-1.13,11.22-6.87,15.29-14.85
						C218.59,83.83,219.91,84.51,220.24,85.34z M199.23,88.12c5.17-0.17,7.15-3.28,8.47-6.53c1.32-3.25,1.26-4.81-0.6-4.42
						C202.53,78.11,199.23,88.12,199.23,88.12z"
              />
              <path
                d="M236.35,82.01c-4.12,8.27-9.57,16.08-15.89,17.39c-4.95,1.02-7.81-1.86-3.85-12.74l6.43-17.67l-4.89,1.01l1.43-3.83
						l4.89-1.01l2.97-8.2l6.76-1.4l-2.97,8.2l4.67-0.97l-1.43,3.83l-4.67,0.97l-7.26,19.87c-2.53,6.93-1.26,7.45,0,7.19
						c3.02-0.63,7.31-6.4,11.22-14C234.7,80.49,236.02,81.18,236.35,82.01z"
              />
              <path
                d="M265.49,75.97c-4.4,8.77-9.4,16.04-15.29,17.26c-4.89,1.01-7.42-2.11-3.46-12.87l1.7-4.79c1.04-2.8,1.92-5.23,1.92-6.07
						c0-0.79-0.66-1.1-1.54-0.92c-1.43,0.3-4.07,2.13-6.49,5.44l-7.97,21.75l-6.76,1.4l15.95-43.57l6.76-1.4l-5.55,15.25
						c0,0,3.68-4.47,8.03-5.37c2.8-0.58,4.73,0.65,4.73,3.51c0,1.63-0.6,3.94-1.76,7.1l-3.13,8.51c-2.58,7.11-1.15,7.43-0.27,7.24
						c2.58-0.54,6.76-6.45,10.56-13.87C263.84,74.46,265.16,75.14,265.49,75.97z"
              />
              <path
                d="M290.56,70.78c-3.74,7.51-10.12,16.19-19.52,18.14c-6.27,1.3-9.51-1.23-9.51-7.01c0-7.86,6.21-22.91,16.28-24.99
						c5.66-1.17,9.24,2.69,5.94,10.56c-2.64,6.33-7.26,9.36-15.34,10.03c0,0-2.69,8.19,4.29,6.75c5.44-1.13,11.22-6.87,15.29-14.85
						C288.92,69.27,290.23,69.95,290.56,70.78z M269.56,73.56c5.17-0.17,7.15-3.28,8.47-6.53c1.32-3.25,1.26-4.81-0.6-4.42
						C272.86,63.55,269.56,73.56,269.56,73.56z"
              />
              <path
                d="M287.98,69.4c1.43-2.82,3.08-6.7,4.67-10.63c-0.99-0.86-1.7-1.84-1.7-3.18c0-2.7,2.75-6.02,5-7.21l3.19,1.42l-1.98,5.24
						c2.2,1.68,7.37,3.3,7.37,7.07c0,0.67-0.16,1.66-0.66,3l-2.2,5.96c-2.53,6.87-1.15,7.43-0.33,7.26c1.92-0.4,4.84-5.05,9.24-13.59
						c0.93-0.14,2.25,0.54,2.58,1.37c-4.23,8.35-8.41,15.84-13.91,16.98c-4.34,0.9-7.59-1.85-3.68-12.43l1.98-5.3
						c0.44-1.21,0.49-1.79,0.49-2.01c0-1.12-1.15-1.89-2.8-2.96c-1.43,3.5-2.97,6.96-4.67,10.4
						C289.63,70.91,288.31,70.23,287.98,69.4z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M139.93,129.63c2.25-6.08,6.32-10.24,14.13-10.28l-1.1,5.17c-2.91,0.32-5,0.53-6.98,5.77l-8.25,22.65
						c-3.02,8.26-8.36,19.03-17.27,20.87c-4.18,0.87-5.99-1.17-6.21-3.82c-2.47,3.15-6.1,6.37-9.95,7.17
						c-5.61,1.16-8.8-2.73-4.18-15.31l7.86-21.45c-6.16,3.63-15.23,7.81-15.23,13.82c0,2.81,2.03,3.96,4.23,3.5v5.67
						c-6.05,1.25-9.95-2.38-9.9-7.78c0-11.23,14.41-16.8,23.97-22.71c1.87-3.08,4.73-5.81,8.03-6.49l1.21,3.74
						c0,0-2.91,1.67-4.73,6.59c-9.07,24.73-9.9,27.21-9.9,27.21c-2.58,7.05-1.04,7.57,0.11,7.33c2.97-0.61,6.93-6.21,9.73-13.25
						l10.34-28.2l6.82-1.41l-11.82,32.38c-2.58,7.05-1.26,7.62,0.11,7.33c3.19-0.66,7.15-6.82,9.79-13.37L139.93,129.63z"
              />
              <path
                d="M169.96,148.12c-3.74,7.51-10.12,16.19-19.52,18.14c-6.27,1.3-9.51-1.23-9.51-7.02c0-7.86,6.21-22.91,16.28-24.99
						c5.66-1.17,9.24,2.69,5.94,10.56c-2.64,6.33-7.26,9.36-15.34,10.03c0,0-2.69,8.2,4.29,6.75c5.44-1.13,11.22-6.87,15.29-14.85
						C168.31,146.61,169.63,147.29,169.96,148.12z M148.95,150.9c5.17-0.17,7.15-3.28,8.47-6.53c1.32-3.25,1.26-4.81-0.6-4.42
						C152.25,140.89,148.95,150.9,148.95,150.9z"
              />
              <path
                d="M195.03,142.93c-3.74,7.51-10.12,16.19-19.52,18.14c-6.27,1.3-9.51-1.23-9.51-7.02c0-7.86,6.21-22.91,16.28-24.99
						c5.66-1.17,9.24,2.69,5.94,10.56c-2.64,6.33-7.26,9.36-15.34,10.03c0,0-2.69,8.19,4.29,6.75c5.44-1.13,11.22-6.87,15.29-14.85
						C193.38,141.42,194.7,142.1,195.03,142.93z M174.03,145.71c5.17-0.17,7.15-3.28,8.47-6.53c1.32-3.25,1.26-4.81-0.6-4.42
						C177.32,135.7,174.03,145.71,174.03,145.71z"
              />
              <path
                d="M222.8,137.18c-3.24,6.51-10.67,16.3-17.1,17.64c-5.22,1.08-7.48-4.01-7.09-9.26c0.27-3.03,1.1-3.6,2.31-3.9
						c1.92-0.45,4.78-1.16,6.76-6.45c0.22-0.49,1.32-3.47,1.32-4.65c0-0.9-0.6-1.22-1.48-1.04c-1.48,0.31-4.01,2.12-6.49,5.39
						l-7.97,21.81l-6.76,1.4l15.95-43.57l6.76-1.4l-5.55,15.25c0,0,3.74-4.48,7.97-5.36c2.86-0.59,4.78,0.64,4.78,3.45
						c0,3.03-2.25,8.16-2.58,8.9c-1.98,4.45-4.95,7.43-8.8,8.56c-0.27,4.89,0.71,6.59,2.69,6.12c3.13-0.71,8.96-6.69,12.7-14.25
						C221.15,135.67,222.47,136.35,222.8,137.18z"
              />
              <path
                d="M247.87,131.98c-3.74,7.51-10.12,16.19-19.52,18.14c-6.27,1.3-9.51-1.23-9.51-7.01c0-7.86,6.21-22.91,16.28-24.99
						c5.66-1.17,9.24,2.69,5.94,10.56c-2.64,6.33-7.26,9.36-15.34,10.03c0,0-2.69,8.19,4.29,6.75c5.44-1.13,11.22-6.87,15.29-14.85
						C246.22,130.47,247.54,131.16,247.87,131.98z M226.87,134.76c5.17-0.17,7.15-3.28,8.47-6.53c1.32-3.25,1.26-4.81-0.6-4.42
						C230.16,124.76,226.87,134.76,226.87,134.76z"
              />
              <path
                d="M277.01,125.95c-4.4,8.72-9.46,16.05-15.29,17.26c-4.95,1.02-7.42-2.23-3.52-12.86l1.7-4.79
						c1.1-2.98,1.92-5.23,1.92-6.07c0-0.79-0.66-1.1-1.48-0.93c-1.43,0.3-4.07,2.13-6.43,5.38l-7.97,21.81l-6.76,1.4l11.49-31.52
						l6.76-1.4l-1.15,3.16c1.98-2.26,5.11-4.71,8.14-5.34c2.69-0.56,4.62,0.67,4.62,3.54c0,1.63-0.6,3.89-1.76,7.1l-3.13,8.51
						c-2.64,7.12-1.21,7.44-0.33,7.26c2.64-0.55,6.82-6.47,10.61-13.88C275.36,124.44,276.68,125.12,277.01,125.95z"
              />
              <path
                d="M306.92,119.76c-4.23,8.46-9.35,16.03-15.34,17.27c-5.22,1.08-5.5-3.41-5.5-3.41s-3.46,5.27-8.03,6.21
						c-3.74,0.77-5.61-1.81-5.61-5.46c0-6.06,5.17-25.05,14.79-27.04c4.78-0.99,5.77,3.35,5.77,3.35l5.94-16.17l6.76-1.4L294.06,125
						c-2.47,6.69-1.43,7.48-0.27,7.24c2.58-0.54,6.71-6.44,10.56-13.87C305.27,118.24,306.59,118.93,306.92,119.76z M286.86,127.56
						l4.23-11.55c0,0,0-3.71-2.64-3.16c-4.89,1.01-9.46,14.76-9.46,18.92c0,1.52,0.6,2.12,1.76,1.88
						C283.94,132.99,286.86,127.56,286.86,127.56z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              d="M152.38,35.2l0.4-0.45c2.1-2.35,3.96-4.43,5.55-6.37c6.71-8.2,7.08-12.79-5.53-23.12c-0.64-0.53-0.92-1.42-0.64-2.25
					c0.37-1.07,1.53-1.63,2.6-1.26l88.79,30.53c0.58,0.17,1.08,0.6,1.33,1.21c0.42,1.05-0.09,2.24-1.14,2.66l-71.6,28.35l0,0
					c-0.88,0.35-1.91,0.05-2.46-0.77c-0.62-0.94-0.37-2.21,0.57-2.83c2.74-1.8,4.25-7.82,4.17-13.8c-0.02-1.51-0.15-3-0.38-4.41
					c-0.32,0.09-0.66,0.11-1.01,0.04l-19.59-4.12l-0.01,0l0,0l-0.03-0.01l0,0l-0.02,0l-0.03-0.01l-0.02,0
					c-0.01,0-0.03-0.01-0.04-0.01l-0.01,0l-0.03-0.01l-0.02-0.01l0,0l-0.03-0.01l-0.03-0.01l-0.01,0c-0.36-0.13-0.67-0.37-0.9-0.66
					l0,0l-0.02-0.03l-0.02-0.02l-0.01-0.01l-0.02-0.03l0-0.01c-0.01-0.01-0.02-0.02-0.02-0.04l0-0.01c-0.02-0.03-0.03-0.05-0.05-0.08
					l0-0.01c-0.02-0.03-0.03-0.05-0.04-0.08l0-0.01l-0.02-0.03l-0.01-0.02l-0.01-0.02L152,37.44L152,37.42l-0.01-0.03l0-0.01
					c-0.15-0.37-0.2-0.78-0.11-1.2l0.01-0.03l0-0.01l0.01-0.02l0.01-0.03l0.01-0.02l0.01-0.03l0.01-0.03l0.01-0.02l0.01-0.03l0-0.01
					l0.01-0.04l0-0.01C152.05,35.63,152.2,35.4,152.38,35.2L152.38,35.2z M172.89,38.51c-0.58-1.39-1.33-2.53-2.25-3.29
					c-1.11-0.92-2.59-1.26-4.46-0.72l0,0l-4.68,1.62L172.89,38.51L172.89,38.51z M162.62,8.77c4.6,5.06,5.76,9.04,4.4,13.29
					c1.14-0.51,2.25-0.82,3.38-0.98c2.36-0.33,4.67,0,7.26,0.45l44.8,7.82L162.62,8.77z M164.81,26.51c-0.91,1.4-2.02,2.88-3.31,4.45
					c-0.31,0.38-0.64,0.77-0.98,1.17l2.33-0.81c3.08-1.14,6.24-2.11,9.42-2.73c3.28-0.64,6.6-0.93,9.92-0.68l30.19,2.26l-35.17-6.13
					c-2.38-0.41-4.47-0.72-6.46-0.44C168.85,23.87,166.95,24.69,164.81,26.51L164.81,26.51z M177.26,38.61
					c0.8,2.54,1.21,5.47,1.25,8.42c0.05,3.95-0.58,7.99-1.81,11.25l56.68-22.44l-51.48-3.85c-2.72-0.2-5.46,0-8.18,0.49
					C175.31,33.99,176.48,36.14,177.26,38.61z"
            />
            <path
              d="M3.6,118.42c-1.1,0-2-0.89-2-2c0-12.32,3.62-23.83,10.75-34.22c6.86-9.99,16.83-18.74,29.63-26.01
					c12.61-7.16,27.67-12.71,44.76-16.5c17.33-3.84,36.35-5.79,56.55-5.79c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2
					c-19.91,0-38.65,1.92-55.69,5.7c-16.71,3.71-31.4,9.12-43.66,16.08c-12.27,6.96-21.8,15.31-28.31,24.8
					c-6.67,9.71-10.05,20.46-10.05,31.96C5.59,117.53,4.7,118.42,3.6,118.42z"
            />
          </g>
          <g>
            <path class="st0" d="M177.2,196.95" />
            <path
              d="M189.14,198.71c-1.07,0-1.95-0.84-1.99-1.92c-0.04-1.1,0.81-2.03,1.92-2.07c18.4-0.73,35.62-3.17,51.18-7.24
					c15.31-4.01,28.72-9.53,39.86-16.4c22.77-14.05,34.8-32.95,34.8-54.65c0-1.1,0.89-2,2-2s2,0.89,2,2c0,11.71-3.28,22.71-9.73,32.7
					c-6.22,9.62-15.29,18.15-26.97,25.35c-11.47,7.08-25.25,12.75-40.94,16.87c-15.84,4.15-33.34,6.63-52.03,7.37
					C189.2,198.71,189.17,198.71,189.14,198.71z"
            />
          </g>
        </g>
        <g>
          <path
            d="M138.95,201.57c0.85-0.83,1.66-1.64,2.44-2.44c0.77-0.8,1.46-1.57,2.08-2.32c0.61-0.75,1.1-1.46,1.46-2.16
				c0.36-0.69,0.54-1.37,0.54-2.04c0-0.44-0.07-0.81-0.21-1.12c-0.14-0.31-0.32-0.56-0.55-0.77c-0.23-0.21-0.49-0.36-0.78-0.46
				c-0.29-0.1-0.6-0.15-0.92-0.15c-0.44,0-0.81,0.07-1.12,0.21c-0.31,0.14-0.55,0.32-0.74,0.55c-0.19,0.23-0.33,0.47-0.42,0.73
				c-0.09,0.26-0.14,0.51-0.14,0.75c0,0.44,0.07,0.84,0.21,1.19c0.14,0.35,0.33,0.66,0.57,0.93l-1.18,1.06
				c-0.32-0.2-0.59-0.48-0.81-0.84c-0.22-0.36-0.39-0.75-0.51-1.17c-0.12-0.42-0.18-0.79-0.18-1.11c0-0.73,0.18-1.41,0.54-2.05
				c0.36-0.63,0.88-1.14,1.57-1.53c0.69-0.39,1.53-0.58,2.53-0.58c0.84,0,1.57,0.19,2.19,0.58c0.62,0.39,1.1,0.9,1.45,1.54
				c0.35,0.64,0.52,1.33,0.52,2.08c0,0.67-0.13,1.32-0.4,1.96c-0.27,0.64-0.62,1.27-1.07,1.89c-0.45,0.62-0.94,1.23-1.47,1.85
				c-0.53,0.61-1.07,1.22-1.6,1.84l-0.86-0.28h5.83v1.86H138.95z"
          />
          <path
            d="M155,201.63c-0.81,0-1.56-0.15-2.24-0.45c-0.68-0.3-1.26-0.74-1.75-1.33c-0.49-0.59-0.86-1.29-1.13-2.12
				c-0.27-0.82-0.4-1.75-0.4-2.78c0-1.12,0.13-2.09,0.38-2.92c0.25-0.83,0.62-1.53,1.11-2.09c0.49-0.56,1.07-0.97,1.75-1.25
				c0.68-0.27,1.44-0.41,2.28-0.41c0.81,0,1.55,0.15,2.23,0.45c0.67,0.3,1.25,0.74,1.74,1.33c0.49,0.59,0.87,1.29,1.14,2.11
				c0.27,0.82,0.41,1.75,0.41,2.79c0,1.05-0.14,1.99-0.41,2.82c-0.27,0.83-0.65,1.53-1.14,2.11c-0.49,0.58-1.07,1.02-1.74,1.31
				C156.55,201.49,155.81,201.63,155,201.63z M154.98,199.78c0.77,0,1.42-0.2,1.94-0.61c0.52-0.41,0.91-0.98,1.18-1.72
				c0.27-0.74,0.41-1.59,0.42-2.55c-0.01-0.93-0.15-1.75-0.42-2.47c-0.27-0.71-0.66-1.27-1.18-1.68c-0.52-0.41-1.16-0.61-1.94-0.61
				c-0.76,0-1.4,0.2-1.92,0.61c-0.52,0.41-0.91,0.97-1.18,1.69c-0.27,0.72-0.4,1.56-0.4,2.52c0,0.93,0.13,1.76,0.4,2.5
				c0.27,0.73,0.66,1.3,1.18,1.71C153.58,199.57,154.22,199.78,154.98,199.78z"
          />
          <path
            d="M162.27,201.57c0.85-0.83,1.66-1.64,2.44-2.44c0.77-0.8,1.46-1.57,2.08-2.32c0.61-0.75,1.1-1.46,1.46-2.16
				c0.36-0.69,0.54-1.37,0.54-2.04c0-0.44-0.07-0.81-0.21-1.12c-0.14-0.31-0.32-0.56-0.55-0.77c-0.23-0.21-0.49-0.36-0.78-0.46
				c-0.29-0.1-0.6-0.15-0.92-0.15c-0.44,0-0.81,0.07-1.12,0.21c-0.31,0.14-0.55,0.32-0.74,0.55c-0.19,0.23-0.33,0.47-0.42,0.73
				c-0.09,0.26-0.14,0.51-0.14,0.75c0,0.44,0.07,0.84,0.21,1.19c0.14,0.35,0.33,0.66,0.57,0.93l-1.18,1.06
				c-0.32-0.2-0.59-0.48-0.81-0.84c-0.22-0.36-0.39-0.75-0.51-1.17c-0.12-0.42-0.18-0.79-0.18-1.11c0-0.73,0.18-1.41,0.54-2.05
				c0.36-0.63,0.88-1.14,1.57-1.53c0.69-0.39,1.53-0.58,2.53-0.58c0.84,0,1.57,0.19,2.19,0.58c0.62,0.39,1.1,0.9,1.45,1.54
				c0.35,0.64,0.52,1.33,0.52,2.08c0,0.67-0.13,1.32-0.4,1.96c-0.27,0.64-0.62,1.27-1.07,1.89c-0.45,0.62-0.94,1.23-1.47,1.85
				c-0.53,0.61-1.07,1.22-1.6,1.84l-0.86-0.28h5.83v1.86H162.27z"
          />
          <path
            d="M172.19,198.4v-0.02l8.01-10.1h0.02v8.63h1.7v1.5h-1.7v3.17h-1.96v-3.17H172.19z M178.4,192.65l-3.37,4.37l-0.3-0.12h3.53
				v-4.61L178.4,192.65z"
          />
        </g>
      </g>
    </svg>
  );
};
