import logo from "./logo.svg";
import "./App.css";
import { StoreProvider } from "Store/storeContext";
import MainComponent from "MainComponent";

function App() {
  return (
    <StoreProvider>
      <MainComponent />
    </StoreProvider>
  );
}

export default App;
