import React from "react";
import { useStore } from "Store/useStore";
import "./Video.scss";

const Video = () => {
  const { dispatch } = useStore();
  const goToRegistration = (country) => {
    dispatch({ type: "CHANGE_COUNTRY", payload: country });
    dispatch({ type: "CHANGE_ROUTE", payload: "/registration" });
  };

  return (
    <div className="atw_video_container">
      <iframe
        width="720"
        height="480"
        src="https://www.youtube.com/embed/4UzSsD50tkg?si=x7R0_oiIe_nL80t2"
        title="All Together Weekend 2024"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <div className="atw_video_registration">
        <a className="registration_cta" onClick={() => goToRegistration("ITA")}>
          Join us from Italy 🇮🇹
        </a>
        <div className="separator" />
        <a className="registration_cta" onClick={() => goToRegistration("POL")}>
          Join us from Poland 🇵🇱
        </a>
      </div>
    </div>
  );
};

export default Video;
