import React, { useReducer, createContext } from 'react';
import { reducer } from './storeReducer';
import { initialStore } from './initialStore';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialStore);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};