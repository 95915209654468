import React, { useState, useEffect } from "react";
import { useStore } from "Store/useStore";
import { Logo } from "Assets/logo_atw.js";
import "./Password.scss";

const Password = () => {
  const { state, dispatch } = useStore();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const correctPassword = "2022";

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === correctPassword) {
      dispatch({ type: "CHANGE_ROUTE", payload: "/video" });
    } else {
      setMessage(`Wrong password, try again!`);
    }
  };

  return (
    <div className="atw_password_container">
      <Logo />
      <div className="atw_password_content">
        <p className="atw_password_copy">Enter the password<br/>to discover the destination</p>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={handleChange}
            placeholder="Enter password..."
          />
          <button type="submit">SUBMIT</button>
        </form>
        <p className="message">{message}</p>
      </div>
    </div>
  );
};

export default Password;
