import React from "react";
import { useStore } from "./Store/useStore";
import Password from "Components/Password/Password";
import Registration from "Components/Registration/Registration";
import logoND from "Assets/logoND.svg";
import "./MainComponent.scss";
import Video from "Components/Video/Video";

import bkg_topRight from "Assets/bkg_topRight.svg";
import bkg_topLeft from "Assets/bkg_topLeft.svg";
import bkg_bottomRight from "Assets/bkg_bottomRight.svg";

const MainComponent = () => {
  const { state } = useStore();

  return (
    <div className="atw_main_container">
      <img className="atw_bkg_topRight" src={bkg_topRight} />
      <img className="atw_bkg_topLeft" src={bkg_topLeft} />
      <img className="atw_bkg_bottomRight" src={bkg_bottomRight} />

      <div className="atw_main_content">

        {state.route === "/password" && <Password />}
        {state.route === "/video" && <Video />}
        {state.route === "/registration" && <Registration />}
      </div>
        <img
          className="atw_logo_nexidigital"
          src={logoND}
          alt={"Nexi Digital Logo"}
        />
    </div>
  );
};

export default MainComponent;
